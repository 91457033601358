import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { useLangContext } from '../context/lang.context'
import Seo from '../components/Seo'
import SectionImage from '../components/SectionImage'

import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { colors, globals, s } from '../style'
import { sContent } from '../style/style'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { gSubtitle } from '../style/config/globals'
// import NewsAll from '../page/News/NewsAll/NewsAll'
import { options } from '../components/Contentful'
import FormCareer from '../page/Career/FormCareer/FormCareer'
import Layout from '../components/Layout'

export default function Job({ data }) {
  const { lang, setLang } = useLangContext()

  const { news } = data

  /* eslint-disable */
  useEffect(() => {
    const newsLang = news?.lang?.slug
    if (newsLang && lang !== newsLang) setLang(newsLang)
  }, [])
  /* eslint-enable */

  if (!news) return <></>

  const {
    slug,
    title,
    desc,
    content,
    image,
    lang: { slug: newsLang },
  } = news

  return (
    <Layout>
      <Seo
        title={title}
        description={desc}
        lang={newsLang}
        image={image.file.url}
      />
      <SectionImage
        title={title}
        desc={desc}
        back={lang === 'pl' ? 'Kariera' : 'Career'}
        image={getImage(image)}
        imagePosition='center center'
      />
      <div css={sContent}>{renderRichText(content, options)}</div>
      <FormCareer page={`kariera/${slug}`} />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    news: contentfulJob(slug: { eq: $slug }) {
      slug
      title
      desc
      lang {
        slug
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            title
            contentful_id
            __typename
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 100
              width: 960
              placeholder: BLURRED
              formats: [JPG]
            )
            sys {
              revision
              type
            }
          }
        }
      }
      image {
        file {
          url
        }
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          placeholder: BLURRED
          formats: [JPG]
        )
      }
    }
  }
`
