import { s, colors, alpha } from './'

export const sPaddingContainer = {
  padding: '0 4rem',
  [s.sm_down]: { padding: '0 2rem' },
}

export const sContent = {
  position: 'relative',
  width: '100%',
  maxWidth: 1280 - 100,
  backgroundColor: colors.container,
  [s.xs]: {
    padding: '50px 25px 25px',
    margin: '0 auto 100px',
  },
  [s.sm]: {
    margin: '0 auto 100px',
    padding: 50,
  },
  // [s.md]: {
  //   img: {
  //     maxWidth: 960,
  //   },
  // },
  [s.lg]: {
    margin: '0 auto 100px',
    padding: 100,
  },
  // img: {
  //   paddingBottom: '2rem',
  // },
  p: {
    color: colors.white.concat(alpha[60]),
    marginBottom: 50,
    lineHeight: '2em',
    [s.xs]: {
      fontSize: '0.875rem',
    },
    fontSize: '1.125rem',
    strong: {
      color: 'white',
    },
  },
  'ul, ol': {
    marginBottom: 50,
  },
  hr: {
    marginBottom: 50,
    opacity: 0.12,
  },
  li: {
    margin: '0 25px',
    fontSize: '0.875rem',
    [s.xs]: {
      fontSize: '0.75rem',
    },
    p: {
      marginBottom: 16,
      lineHeight: '1.618em',
    },
  },
  'h1, h2, h3, h4, h5, h6': {
    marginBottom: 16,
  },
  blockquote: {
    padding: 50,
    marginBottom: 50,
    backgroundColor: colors.white.concat(alpha[12]),
    p: {
      margin: 0,
      lineHeight: '1.618em',
      fontSize: '1.5rem',
      fontFamily: 'Staatliches',
    },
  },
  h2: { fontSize: '2.5rem', [s.xs]: { fontSize: '1.5rem' } },
  h3: { fontSize: '2rem', [s.xs]: { fontSize: '1.25rem' } },
  h4: { fontSize: '1.75rem', [s.xs]: { fontSize: '1.125rem' } },
  h5: { fontSize: '1.5rem', [s.xs]: { fontSize: '1rem' } },
  h6: { fontSize: '1.25em', [s.xs]: { fontSize: '0.875rem' } },
  a: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    color: colors.main,
  },
}

export const Modal = {
  '.modal-video': {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: '1000000',
    cursor: 'pointer',
    opacity: 1,
  }
}